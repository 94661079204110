/* OVERWRITE */
.form-control, .form-control:focus {
    background: none;
}

/* CUSTOM STYLES */
.react-daterange-picker {
    /* display: block !important;
    background-color: white;
    text-align: center;
    size: 3rem;
    border-color: hsl(0, 0%, 80%);
    border-top-color: rgb(204, 204, 204);
    border-right-color: rgb(204, 204, 204);
    border-bottom-color: rgb(204, 204, 204);
    border-left-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-style: solid;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-width: 1px;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px; */

}

.react-daterange-picker .react-daterange-picker__wrapper {
    border: none !important;
    /* min-height: 38px; */
    padding-left: 1rem;
    padding-right: 1rem;
}

.react-daterange-picker__clear-button__icon.react-daterange-picker__button__icon {
    height: 15px;
    width: 15px;
    opacity: 0.6;
}

.react-daterange-picker__calendar-button__icon.react-daterange-picker__button__icon {
    height: 14px;
    /* width: 15px; */
    opacity: 0.6;
}

.tox.tox-tinymce {
    background-color: #fff;
    border: 1px solid #e8ebf3;
}


.form-check-input {
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.8em;
    outline: none;
}

.form-check-input:checked {
    background-color: #1890ff;
    border-color: #1890ff;
    
}

