@import './assets/css/bootstrap.min.css';
@import './assets/css/icons.min.css';
@import './assets/css/app.css';
@import 'notyf/notyf.min.css';

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Roboto','Segoe UI' , 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-white {
  background: #ffffff;
}


.action_toggler {
  border: none;
  outline: none;
  background: none;
  font-size: 20px;
  font-weight: 600;
  line-height: 10px;
  opacity: 0.5;
}


.custom-shadow-one {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}


.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}