#body .dark-sidebar .left-sidebar .navbar-vertical .navbar-nav .has-submenu:after {
    display: block;
    content: "\f105";
    font-family: "Line Awesome Free";
    font-weight: 900;
    margin-left: auto;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s,-webkit-transform .2s;
}

#body .enlarge-menu .left-sidebar .navbar-vertical .navbar-nav .has-submenu:after {
    display: none;
}

#body .dark-sidebar .left-sidebar .navbar-vertical .navbar-nav .expanded {
    color: #ccd1e0;
    /* background-color: rgba(204,209,224,.03); */
}

#body .dark-sidebar .left-sidebar .navbar-vertical .navbar-nav .expanded .menu-icon {
    color: #ccd1e0;
}

#body .dark-sidebar .left-sidebar .navbar-vertical .navbar-nav .expanded:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

#body .dark-sidebar .left-sidebar .navbar-vertical .navbar-nav .nav-link-active {
    color: #1890FF;
    /* background-color: rgba(204,209,224,.03); */
}

#body .dark-sidebar .left-sidebar .navbar-vertical .navbar-nav .nav-link-active .menu-icon {
    color: #1890FF;
    /* background-color: rgba(204,209,224,.03); */
}